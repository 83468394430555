import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import GridCountdown from "./GridCountdown";
import ProductOptionsDialog from "./ProductOptionsDialog";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Product = (props) => {
  const { product } = props;
  const width = window.innerWidth;

  const [style, setStyle] = useState({
    display: "none",
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const image1 = product.variants
    ? product.variants[0].image
    : product.images[0];
  const image2 = product.images[1];
  const firstImgSrc = image1 ? image1.transformedSrc : "";
  const [imageSrc, setImageSrc] = useState(firstImgSrc);

  const handleImgHover = () =>
    setImageSrc((prev) => (image2 ? image2.transformedSrc : prev));
  const handleImgOut = () => setImageSrc(image1 ? image1.transformedSrc : "");

  return (
    <>
      <div className="Product" key={product.id}>
        <div
          className="Product__ImageContainer"
          // handle mouseEnter and mouseLeave if screensize bigger than 960px
          onMouseEnter={width > 960 ? () => handleImgHover() : null}
          onMouseLeave={width > 960 ? () => handleImgOut() : null}
        >
          {image1 ? (
            <a href={`/products/${product.handle}`}>
              <img
                className="Product__Image"
                src={imageSrc}
                alt={`${product.title} product shot`}
              />
            </a>
          ) : (
            <img
              className="Product__Image"
              src="placeholder-image.png"
              alt={`${product.title} product shot`}
            />
          )}

          {product.endDate && <GridCountdown date={product.endDate} />}
          {(product.title.includes("Orange") ||
            product.title.includes("Bat Socks") ||
            (product?.totalInventory === 0 &&
              product.title.includes("Plush")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Banana Bag")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Embroidered Sweatpants")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Stork")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Embroidered Monke Socks")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Santa Monke Socks")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Rust Monke Gaming Mousepad")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Frutta")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Checkered")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Staff")) ||
            (product.title.includes("Abbey")) ||
            (product?.totalInventory === 0 &&
              product.title.includes("Monke Necklace"))) && (
              <p className="sold-out-item">SOLD OUT</p>
            )}
        </div>
        <div className="Product__info">
          <h4 className="Product__subtitle">{product.title}</h4>
          <p className="Product__price">
            {/* {product.variants[0].compareAtPrice ? (
              <div>
                <span className="compared-price">
                  ${product?.variants[0]?.compareAtPrice.amount.slice(0, -2)}
                </span>{" "}
                ${product.variants[0].price.amount.slice(0, -2)}
              </div>
            ) : (
              <div>
                <span className="compared-price">
                  ${product.variants[0].price.amount.slice(0, -2)}
                </span>{" "}
                $
                {parseInt(product.variants[0].price.amount.slice(0, -2)) -
                  parseInt(product.variants[0].price.amount.slice(0, -2) * 0.20)}
              </div>
            )} */}
            {product.variants[0].compareAtPrice && (
              <span className="compared-price">
                ${product?.variants[0]?.compareAtPrice.amount.slice(0, -2)}
              </span>
            )}{" "}
            ${product.variants[0].price.amount.slice(0, -2)}
          </p>
        </div>
      </div>
      <ProductOptionsDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        product={product}
      />
    </>
  );
};

Product.propTypes = {
  product: PropTypes.object,
};

export default Product;
