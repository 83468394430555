import { React } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import Cart from "./Cart";
import NewNav from "./NewNav";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import { PartyModeSharp } from "@material-ui/icons";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const Accessories = (props) => {
  const { products } = useShopify();
  const params = useParams();
  const width = window.innerWidth;
  let pageTitle = "";
  let banners = [];

  const landingBanners =
    width < 426
      ? ["/./Assets/accessoriesChristmasCover.jpg"]
      : ["/./Assets/accessoriesChristmasCover.jpg"];
  const plushiesBanners = ["/./Assets/summerPlushiesCover.jpg"];
  const hatsBanners = ["/./Assets/hatsCover.jpg"];
  const caseBanners = ["/./Assets/essentialscasescover.jpg"];
  const socksBanners =
    width < 426
      ? ["/./Assets/mobileSocksCover.jpg"]
      : ["/./Assets/socksCover.jpg"];
  const stickersBanners = ["/./Assets/stickersCover.jpg"];
  const posterBanners = ["/./Assets/postersCover.jpg"];
  const bagsBanners = ["/./Assets/bagsCover.jpg"];
  const mousepadsBanners = ["/./Assets/mousepadsCover.jpg"];
  const puzzleBanners = ["/./Assets/essentialspuzzlecover.jpg"];
  const keychainBanners = ["/./Assets/essentialskeychainscover.jpg"];
  const jewerlyBanners = ["/./Assets/jewerlyCover.jpg"];
  const mugsBanners =
    width < 600
      ? ["/./Assets/mugsCover.jpg"]
      : ["/./Assets/essentialsmugscover.jpg"];
  const magnetBanners = ["/./Assets/magnetCover.jpg"];
  const rugsBanners = ["/./Assets/rugsCover.jpg"];
  const bobbleheadsBanners = ["/./Assets/bobbleheadsCover.jpg"];

  switch (params.id) {
    case "accessories":
      banners = landingBanners;
      break;
    case "plushies":
      banners = plushiesBanners;
      break;
    case "hats":
      banners = hatsBanners;
      break;
    case "socks":
      banners = socksBanners;
      break;
    case "stickers":
      banners = stickersBanners;
      break;
    case "cases":
      banners = caseBanners;
      break;
    case "posters":
      banners = posterBanners;
      break;
    case "bags":
      banners = bagsBanners;
      break;
    case "mousepads":
      banners = mousepadsBanners;
      break;
    case "puzzles":
      banners = puzzleBanners;
      break;
    case "keychains-pins":
      banners = keychainBanners;
      break;
    case "mugs":
      banners = mugsBanners;
      break;
    case "jewelry":
      banners = jewerlyBanners;
      break;
    case "magnets":
      banners = magnetBanners;
      break;
    case "rugs":
      banners = rugsBanners;
      break;
    case "bobbleheads":
      banners = bobbleheadsBanners;
      break;
    default:
      banners = landingBanners;
      break;
  }

  switch (params.id) {
    case "accessories":
      pageTitle = "All Monke Accessories";
      break;
    case "all":
      pageTitle = "All Monke Accessories";
      break;
    case "plushies":
      pageTitle = "All Monke Plushies";
      break;
    case "hats":
      pageTitle = "All Monke Hats";
      break;
    case "socks":
      pageTitle = "All Monke Socks";
      break;
    case "stickers":
      pageTitle = "All Monke Stickers";
      break;
    case "cases":
      pageTitle = "All Monke Phone Cases";
      break;
    case "posters":
      pageTitle = "All Monke Posters";
      break;
    case "bags":
      pageTitle = "All Monke Bags";
      break;
    case "mousepads":
      pageTitle = "All Monke Mousepads";
      break;
    case "mugs":
      pageTitle = "All Monke Mugs";
      break;
    case "puzzles":
      pageTitle = "All Monke Puzzles";
      break;
    case "keychains-pins":
      pageTitle = "All Monke Keychains & Pins";
      break;
    case "jewelry":
      pageTitle = "All Monke Jewelry";
      break;
    case "magnets":
      pageTitle = "All Monke Magnets";
      break;
    case "rugs":
      pageTitle = "All Monke Rugs";
      break;
    case "bobbleheads":
      pageTitle = "All Monke Bobbleheads";
      break;
    default:
      pageTitle = "All Monke Accessories";
      break;
  }

  return (
    <>
      <NewNav />

      <div className="App__header">
        <Carousel
          className="App__carousel"
          autoPlay
          infiniteLoop
          interval={7000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          dynamicHeight={true}
        >
          {banners.map((banner, i) => (
            <img key={i} src={banner} alt={`banner ${i}`} />
          ))}
        </Carousel>
      </div>

      {/* <div className="App__clothing-title">
        <h1 className="App__clothing-text">{pageTitle}</h1>
      </div> */}

      {(!params.id || params.id === "all") && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.productType === "Hat" ||
                            product.productType === "Phone Case" ||
                            product.productType === "Mug" ||
                            product.handle.includes("plushie") ||
                            product.title.includes("Plush") ||
                            product.title.includes("Socks") ||
                            product.title.includes("Sticker") ||
                            product.title.includes("Mousepad") ||
                            product.handle.includes("tote") ||
                            product.handle.includes("airpod") ||
                            product.handle.includes("cape")
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "plushies" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.handle.includes("plushie") ||
                            product.handle.includes("plush") ||
                            product.title === "Brinda"
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "hats" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.handle.includes("hat") ||
                            product.handle.includes("beanie") ||
                            product.handle.includes("snapback")
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "socks" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.title.includes("Socks") ||
                            product.handle.includes("socks")
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "stickers" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("sticker")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "cases" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("case")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "posters" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("poster")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "bags" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.handle.includes("bag") ||
                            product.handle.includes("tote")
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "mousepads" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("mousepad")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "mugs" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("mug")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "puzzles" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("puzzle")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "keychains-pins" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (
                            product.handle.includes("keychain") ||
                            product.handle.includes("pin")
                          ) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "jewelry" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("necklace")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "magnets" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("magnet")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "rugs" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("rug")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "bobbleheads" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(function (product) {
                        for (var misc in products) {
                          if (product.handle.includes("bobble")) {
                            return misc;
                          }
                        }
                      })
                      .map((misc, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={misc} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      <Cart />
      <Footer />
    </>
  );
};

export default Accessories;
