import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useShopify } from "../hooks";
import { Grid } from "@material-ui/core";
import Footer from "./Footer";
import Product from "./Product";
import NewNav from "./NewNav";
import ProductPage from "./ProductPage";
import { useState } from "react";


const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 1;
    if (width < 1280) return 1;
    return 1;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const ProductOptionsPage = (props) => {
  const { products } = useShopify();
  const location = useLocation();
  const params = useParams();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <>
      <NewNav />
      
      {products && products.length > 0 ? (
        products
          .filter((product) => product.handle === (params.id))
          .map((item, i) => (
            <ProductPage product={item} history={props.history} />
          ))
      ) : (
        <Placeholder />
      )}


      <Footer />
    </>
  );
};

export default ProductOptionsPage;
