import React from "react";
import { Carousel } from "react-responsive-carousel";
import Footer from "./Footer";
import NewNav from "./NewNav";

const Gallery = () => {
  const christmasBanners = [
    "/./Assets/christmasGallery1.jpg",
    "/./Assets/christmasGallery2.jpg",
    "/./Assets/christmasGallery3.jpg",
    "/./Assets/christmasGallery4.jpg",
    "/./Assets/christmasGallery5.jpg",
    "/./Assets/christmasGallery6.jpg",
    "/./Assets/christmasGallery7.jpg",
    "/./Assets/christmasGallery8.jpg",
  ];
  const spaceBanners = [
    "/./Assets/spaceGallery1.jpg",
    "/./Assets/spaceGallery2.jpg",
    "/./Assets/spaceGallery3.jpg",
    "/./Assets/spaceGallery4.jpg",
    "/./Assets/spaceGallery5.jpg",
    "/./Assets/spaceGallery6.jpg",
  ];

  const summerBanners = [
    "/./Assets/summerGallery1.jpg",
    "/./Assets/summerGallery2.jpg",
    "/./Assets/summerGallery3.jpg",
    "/./Assets/summerGallery4.jpg",
    "/./Assets/summerGallery5.jpg",
    "/./Assets/summerGallery6.jpg",
    "/./Assets/summerGallery7.jpg",
    "/./Assets/summerGallery8.jpg",
  ];

  const valentineBanners = [
    "/./Assets/valentineGallery1.jpg",
    "/./Assets/valentineGallery2.jpg",
    "/./Assets/valentineGallery3.jpg",
    "/./Assets/valentineGallery4.jpg",
    "/./Assets/valentineGallery5.jpg",
    "/./Assets/valentineGallery6.jpg",
    "/./Assets/valentineGallery7.jpg",
  ];

  return (
    <>
      <NewNav />
      <div className="gallery-container">
        <div className="gallery-promovids-container">
        <h1 className="gallery-container-content-title">Monke Promos</h1>
          <div className="gallery-promovids-container-content">
            <div className="gallery-promovids-video">
              <iframe
                className="gallery-promovids-video-frame"
                src="https://www.youtube.com/embed/13ohU637wDg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="gallery-promovids-video">
              <iframe
                className="gallery-promovids-video-frame"
                src="https://www.youtube.com/embed/Ymxtg2CNa6o"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="gallery-promovids-video">
              <iframe
                className="gallery-promovids-video-frame"
                src="https://www.youtube.com/embed/8xUlSfaEeow"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="gallery-promovids-video">
              <iframe
                className="gallery-promovids-video-frame"
                src="https://www.youtube.com/embed/9zk3KmUyhl0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="gallery-promovids-video">
              <iframe
                className="gallery-promovids-video-frame"
                src="https://www.youtube.com/embed/3d5UaBRov4g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="gallery-container">
        <div className="gallery-container-content">
          <h1 className="gallery-container-content-title">Monke Valentine</h1>
          <Carousel
            className="Gallery__carousel"
            autoPlay
            infiniteLoop
            interval={7000}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            showArrows={true}
          >
            {valentineBanners.map((banner, i) => (
              <img key={i} src={banner} alt={`banner ${i}`} />
            ))}
          </Carousel>
          <h1 className="gallery-container-content-title">Monke Christmas</h1>
          <Carousel
            className="Gallery__carousel"
            autoPlay
            infiniteLoop
            interval={7000}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            showArrows={true}
          >
            {christmasBanners.map((banner, i) => (
              <img key={i} src={banner} alt={`banner ${i}`} />
            ))}
          </Carousel>
          <h1 className="gallery-container-content-title">Monke Space</h1>
          <Carousel
            className="Gallery__carousel"
            autoPlay
            infiniteLoop
            interval={7000}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            showArrows={true}
          >
            {spaceBanners.map((banner, i) => (
              <img key={i} src={banner} alt={`banner ${i}`} />
            ))}
          </Carousel>
          <h1 className="gallery-container-content-title">Monke Summer</h1>
          <Carousel
            className="Gallery__carousel"
            autoPlay
            infiniteLoop
            interval={7000}
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            showArrows={true}
          >
            {summerBanners.map((banner, i) => (
              <img key={i} src={banner} alt={`banner ${i}`} />
            ))}
          </Carousel>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Gallery;
