import { React } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import Cart from "./Cart";
import NewNav from "./NewNav";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const Clothing = (props) => {
  const { products } = useShopify();
  const params = useParams();
  const width = window.innerWidth;
  let pageTitle = "";
  let banners = [];

  const landingBanners =
    width < 426 ? ["/./Assets/shopAll.jpg"] : ["/./Assets/shopAll.jpg"];
  const hoodiesBanners = ["/./Assets/candyhoodiescover.jpg"];

  const shirtsBanners =
    width < 426
      ? ["/./Assets/shirtsCover.jpg"]
      : ["/./Assets/shirtsCover.jpg"];
  const bottomsBanners =
    width < 426
      ? ["/./Assets/mobileBottomsCover.jpg"]
      : ["/./Assets/bottomsCover.jpg"];

  switch (params.id) {
    case "clothing":
      banners = landingBanners;
      break;
    case "hoodies":
      banners = hoodiesBanners;
      break;
    case "shirts":
      banners = shirtsBanners;
      break;
    case "bottoms":
      banners = bottomsBanners;
      break;
    default:
      banners = landingBanners;
      break;
  }

  switch (params.id) {
    case "clothing":
      pageTitle = "All Monke Clothing";
      break;
    case "all":
      pageTitle = "All Monke Clothing";
      break;
    case "hoodies":
      pageTitle = "All Monke Hoodies";
      break;
    case "shirts":
      pageTitle = "All Monke T-Shirts";
      break;
    case "accessories":
      pageTitle = "All Monke Accessories";
      break;
    case "bottoms":
      pageTitle = "All Monke Bottoms";
      break;
    default:
      pageTitle = "All Monke Clothing";
      break;
  }

  return (
    <>
      <NewNav />
      <div className="App__header_latest">
        <Carousel
          className="App__carousel"
          autoPlay
          infiniteLoop
          interval={7000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
        >
          {banners.map((banner, i) => (
            <img key={i} src={banner} alt={`banner ${i}`} />
          ))}
        </Carousel>
      </div>

      {/* <div className="App__clothing-title">
        <h1 className="App__clothing-text">{pageTitle}</h1>
      </div> */}

      {(!params.id || params.id === "all") && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(
                        (product) =>
                          (product.productType === "T-Shirt" ||
                            product.productType === "Hoodie" ||
                            product.productType === "Hat" ||
                            product.title.includes("trunk") ||
                            product.productType === "Sweatshirt") &&
                          !product.title.includes("Mousepad") &&
                          !product.title.includes("Brinda") &&
													!product.title.includes("Plush") &&
													!product.title.includes("Hat") &&
                          !product.title.includes("Snapback") &&
													!product.title.includes("Beanie")
                      )
                      .map((shirt, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={shirt} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "hoodies" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(
                        (product) =>
                          (product.productType === "Hoodie" &&
                            !product.title.includes("trunks") &&
                            !product.title.includes("Plush") &&
                            !product.title.includes("Mousepad") &&
                            !product.title.includes("Joggers")) ||
                          (product.productType === "Sweatshirt" &&
                            !product.title.includes("trunks") &&
                            !product.title.includes("Plush") &&
                            !product.title.includes("Mousepad") &&
                            !product.title.includes("Joggers"))
                      )
                      .map((shirt, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={shirt} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "shirts" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(
                        (product) =>
                          product.productType === "T-Shirt" &&
                          !product.title.includes("Shorts") &&
                          !product.title.includes("Trunks")
                      )
                      .map((shirt, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={shirt} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      {params.id === "bottoms" && (
        <div className="Products-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={false} md={1} xl={2} />
            <Grid item xs={12} md={10} xl={8}>
              <div className="Products-wrapper__inner">
                <Grid container spacing={3}>
                  {products && products.length > 0 ? (
                    products
                      .filter(
                        (product) =>
                          product.handle.includes("shorts") ||
                          product.title.includes("trunks") ||
                          product.handle.includes("joggers") || 
                          product.handle.includes("sweatpants")
                      )
                      .map((shirt, i) => (
                        <Grid item xs={6} md={4} lg={3} key={i}>
                          <Product product={shirt} history={props.history} />
                        </Grid>
                      ))
                  ) : (
                    <Placeholder />
                  )}
                </Grid>
              </div>
              <Grid item xs={false} md={2} />
            </Grid>
          </Grid>
        </div>
      )}
      <Cart />
      <Footer />
    </>
  );
};

export default Clothing;
