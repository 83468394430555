import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
	<div className="Footer">
		<div className="Footer_bottom">
			<div className="footer-logo-container">
				<Link to="/">
					<img
						className="footer-logo"
						src="/././Assets/santamonke-logo.png"
						alt="navbar logo"
					/>
				</Link>
				<ul className="footer-social-links">
					<li>
						<a
							href="https://twitter.com/monkeclothing "
							target="_blank"
							rel="noreferrer"
							className="social-link-footer"
						>
							<i className="fab fa-x-twitter fa-1x"></i>
						</a>
					</li>
					<li>
						<a
							href="https://www.instagram.com/monkeclothing/"
							target="_blank"
							rel="noreferrer"
							className="social-link-footer"
						>
							<i className="fab fa-instagram fa-1x"></i>
						</a>
					</li>
				</ul>
			</div>
			<div className="Footer_top-links">
				<a
					href="https://merchforall.com/pages/sizes/"
					target="_blank"
					rel="noreferrer"
					className="Footer_top-link"
				>
					Sizes and Fit
				</a>
				<span>|</span>
				<a
					href="https://merchforall.com/pages/shipping-and-returns/"
					target="_blank"
					rel="noreferrer"
					className="Footer_top-link"
				>
					Shipping & Returns
				</a>
				<span>|</span>
				<a
					href="https://merchforall.com/contact/"
					target="_blank"
					rel="noreferrer"
					className="Footer_top-link"
				>
					Contact Us
				</a>
				<span>|</span>
				<a
					href="https://merchforall.com/legal/privacy/"
					target="_blank"
					rel="noreferrer"
					className="Footer_top-link"
				>
					Privacy Policy
				</a>
				<span>|</span>
				<a
					href="https://merchforall.com/legal/terms/"
					target="_blank"
					rel="noreferrer"
					className="Footer_top-link"
				>
					Terms of Service
				</a>
			</div>

		</div>
	</div>
);


export default Footer;