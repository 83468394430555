import React from "react";
import { Carousel } from "react-responsive-carousel";
import Footer from "./Footer";
import NewNav from "./NewNav";

import supportText from "./supportText";

const Support = () => {
  const banners = ["/./Assets/supportCover.jpg"];
  return (
    <>
      <NewNav />

      <div className="App__header">
        <Carousel
          className="App__carousel"
          autoPlay
          infiniteLoop
          interval={7000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
        >
          {banners.map((banner, i) => (
            <img key={i} src={banner} alt={`banner ${i}`} />
          ))}
        </Carousel>
      </div>

      {/* <div className="App__title">
        <h1 className="App__title-text">Monke Clothing Support</h1>
      </div> */}

      <div className="monke-support">
        <div className="support-container">
          <p className="support-desc">
            Orders are sent out via USPS and are typically processed within five
            business days of purchase, excluding signed merchandise. Delivery
            time is dependent upon location and can take up to 21 business days.
          </p>
          <br />
          {supportText.map((text) => {
            return (
              <div className="support-text-group">
                <h3>{text.heading}</h3>
                <p>{text.description}</p>
              </div>
            );
          })}

          <div className="support-text-group">
            <h3>CONTACT US:</h3>
            <a href="mailto:support@merchforall.com">support@merchforall.com</a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Support;
