import { React, useState } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import Cart from "./Cart";
import NewNav from "./NewNav";
import { Carousel } from "react-responsive-carousel";
import { Button } from "./ButtonElement";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const SaleProducts = (props) => {
  const { products } = useShopify();
  const [latestEnabled, setLatestEnabled] = useState(true);
  const width = window.innerWidth;

  const displayLatest = () => {
    setLatestEnabled(true);
  };

  const productFunctionsArr = [displayLatest];

  const banners = ["/./Assets/saleCoverPic.jpg"];


  return (
    <>
      <NewNav props={productFunctionsArr} />
      <div className="App__header">
        <Carousel
          className="App__carousel"
          autoPlay
          infiniteLoop
          interval={7000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          dynamicHeight={true}
        >
          {banners.map((banner, i) => (
            <img key={i} src={banner} alt={`banner ${i}`} />
          ))}
        </Carousel>
      </div>

      {/* <div className="App__clothing-title">
        <h1 className="App__clothing-text">20% OFF Entire Site</h1>
      </div> */}

      <div className="Products-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={false} md={1} xl={2} />
          <Grid item xs={12} md={10} xl={8}>
            <div className="Products-wrapper__inner">
              <Grid container spacing={3}>
              {products && products.length > 0 ? (
                  products
                    .filter(function (product) {
                      for (var latest in products) {
                        if (
                          product.handle.includes("solar-colors") ||
													product.handle.includes("spider-monke") ||
													product.handle.includes("santa-monke-socks") ||
													product.title.includes("Laser Eye") || 
                          product.title.includes("Tree Frog") ||
                          product.title.includes("Cupid Monke Plush") ||
                          product.title === ("Snorkeling Monke swim trunks")
                        ) {
                          return latest;
                        }
                      }
                    })
                    .map((latest, i) => (
                      <Grid item xs={6} md={4} lg={3} key={i}>
                        <Product product={latest} history={props.history} />
                      </Grid>
                    ))
                ) : (
                  <Placeholder />
                )}
              </Grid>
            </div>
            <Grid item xs={false} md={2} />
          </Grid>
        </Grid>
      </div>
      <Cart />
      <Footer />
    </>
  );
};

export default SaleProducts;
