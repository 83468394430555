import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { useShopify } from "../hooks";
import { useCollection } from "../context/collection";
import createTheme from "../theme/material-ui";
import NotFound from "./NotFound";
import ProductLanding from "./ProductLanding";
import LatestRelease from "./LatestRelease";
import Clothing from "./Clothing";
import Accessories from "./Accessories";
import Support from "./Support";
import Gallery from "./Gallery";
import SaleProducts from "./SaleProducts";
import ProductOptionsPage from "./ProductOptionsPage";


const App = () => {
  const { createShop, createCheckout, fetchCollection } = useShopify();
  const { shopifyID, accentColor, accentColorLuminance } = useCollection();


  const theme = useMemo(
    () => createTheme(accentColor, accentColorLuminance),
    [accentColor, accentColorLuminance]
  );



  useEffect(() => {
		createShop();
		createCheckout();
		fetchCollection(shopifyID);
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, [shopifyID]);


  return (
    <ThemeProvider theme={theme}>
      <div id="App" style={{ position: "relative" }}>
      {/* Reload page when user hits browser back button */}
      

        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/latest" />
            </Route>
            <Route exact path="/latest" component={LatestRelease} />
            <Route exact path="/clothing" component={Clothing} />
            <Route exact path="/clothing/:id" component={Clothing} />
            <Route exact path="/accessories" component={Accessories} />
            <Route exact path="/accessories/:id" component={Accessories} />
            <Route exact path="/products" component={LatestRelease} />
            <Route
              exact
              path="/products/:id"
              key={window.location.pathname}
              component={ProductOptionsPage}
            />
            <Route exact path="/sale" component={SaleProducts} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
